import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { History } from 'history';

import Button from '../../shared/components/buttons/Button';
import { actions } from '../../store/reducers/geral';
import { StepInterface } from './Journey';
import { ModalData } from '../../shared/modals/geral/geral';
import { handleJourneyStartContest } from '../../shared/functions/gameStarters/contests';
import { handleJourneyStartChallenge } from '../../shared/functions/gameStarters/challenges';
import { useCustomStyle } from '../../shared/functions/hooks';
import RichTextEditor from '../richEditor/RichTextEditor';
import { RootStateGlobal } from '../../store/reducer';
import { t } from '../../i18n';

interface ModalStepDescriptionInterface {
  exitToPath: string;
  step: StepInterface;
  history: History;
  i: number;
  refreshJourney: () => void;
}

const ModalStepDescription = ({
  exitToPath,
  step,
  history,
  i,
  refreshJourney,
}: ModalStepDescriptionInterface) => {
  const dispatch = useDispatch();
  const closeModal = () => dispatch(actions.closeModal());
  const setModalData = (x: ModalData) => dispatch(actions.setModal(x));
  const { primaryColor } = useCustomStyle();
  const { userData } = useSelector((state: RootStateGlobal) => state.userReducer);

  const closeModalAndStart = () => {
    switch (step.type) {
      case 'Contest':
        handleJourneyStartContest(step.target, exitToPath, history, closeModal, setModalData);
        break;
      case 'Challenge':
        handleJourneyStartChallenge({
          challenge: step.target,
          setModalData,
          closeModal,
          exitToPath,
          history,
          callback: refreshJourney,
          dispatch,
          customBackground: primaryColor.color,
        });
        break;
      default:
        break;
    }
  };

  const buildPlayButton = (step: StepInterface) => {
    let disabled = ['disabled', 'expired', 'waiting'].includes(step.status);

    // TODO: While we don't have a specific solution for companies/custom settings, we'll handle it ugly like so:
    const aquilaCompanyId = 'cdb33eac-2d6f-41b4-9d91-5ee5f664fadb'; // Aquila
    let buttonText = t('play');

    const isAquila = userData && userData.idCompany === aquilaCompanyId;
    if (isAquila) buttonText = 'INICIAR';

    // Only runs these extra checks for Syngenta - SIPAT
    if (userData && ['0e557d3c-47b7-4b2b-a5f2-7e3c1c1dbb55'].includes(userData.idCompany)) {
      if (step.type === 'Challenge') {
        const { locked, text } = step.target.status;
        if (locked) [disabled, buttonText] = [true, text];
      } else if (['Contest', 'Stage'].includes(step.type)) {
        const { amountPlayed, entryLimit } = step.target;
        if (entryLimit && amountPlayed >= entryLimit)
          [disabled, buttonText] = [true, t('modalLimit')];
      }
    }

    return (
      <Button
        typeButton="card-purple"
        disabled={disabled}
        onClick={closeModalAndStart}
        style={{ marginTop: '16px' }}
        customBackground={primaryColor.color}
      >
        {buttonText}
      </Button>
    );
  };

  return (
    <>
      <p className="journey__modal journey__modal-title">
        {`${t('step')} ${i + 1} - ${step.title}`}
      </p>
      {!!step.description.length && (
        <>
          <p className="journey__modal journey__modal-description-label">{t('description')}</p>
          <p className="journey__modal-description">
            <RichTextEditor value={step.description} />
          </p>
        </>
      )}
      {buildPlayButton(step)}
    </>
  );
};

export default ModalStepDescription;
