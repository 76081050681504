import { withTranslation } from 'react-i18next';
import React, { createContext, useCallback, useState } from 'react';
import { Provider } from 'react-redux';
import { Provider as RollbarProvider, ErrorBoundary } from '@rollbar/react';

import { Helmet } from 'react-helmet';
import { GoogleOAuthProvider } from '@react-oauth/google';
import queryString from 'query-string';
import Routes from './Routes';
import BlockScreen from './shared/components/loadings/blockScreen/BlockScreen';
import Modal from './shared/components/modal/Modal';
import SnackBar from './shared/components/snackbar/Snackbar';
import store from './store';
import './globalStyles.css';
import { startAnalitycs } from './shared/functions/analytics';
import FullScreen from './shared/components/fullScreen/FullScreen';
import './datadog';
import ServiceWorkerReloader from './ServiceWorkerReloader';
import ContactUsButton from './shared/components/support/ContactUsButton';
import { environment } from './shared/functions/environment';

startAnalitycs();

// Used by tooltip-like tutorial to point to elements during onboarding.
export const TutorialContext = createContext<{
  ref: ((node: HTMLElement | null) => void) | null;
  tutorialAnchor: HTMLElement | null;
}>({ ref: null, tutorialAnchor: null });

export const SubdomainContext = createContext<{
  slug?: string;
}>({ slug: undefined });

// ROLLBAR CONFIG
const nodeEnv = environment();
const rollbarConfig = {
  accessToken: process.env.REACT_APP_ROLLBAR_ACCESS_TOKEN,
  captureUncaught: true,
  captureUnhandledRejections: true,
  payload: { environment: nodeEnv },
  enabled: nodeEnv === 'production', // TODO: create the staging environment
  ignoredMessages: [
    'n: Worker was terminated.', // https://github.com/wojtekmaj/react-pdf/issues/651 - TODO: update to v6 to try to fix it
  ],
};

const App = () => {
  const slug = `${queryString.parse(window.location.search).slug || ''}`;
  const [tutorialAnchor, setTutorialAnchor] = useState<HTMLElement | null>(null);
  const ref = useCallback((node: HTMLElement | null) => {
    setTutorialAnchor(node);
  }, []);

  return (
    <RollbarProvider config={rollbarConfig}>
      <ErrorBoundary>
        <GoogleOAuthProvider
          clientId={`${process.env.REACT_APP_GOOGLE_CLIENT_ID}`}
          // onScriptLoadSuccess={noop}
          // onScriptLoadError={noop} // TODO: implement the onScriptLoadError logic
        >
          <Provider store={store}>
            <Helmet>
              <meta name="google" content="notranslate" />
            </Helmet>
            <FullScreen />
            <BlockScreen />
            <Modal />
            <SnackBar />
            <TutorialContext.Provider value={{ ref, tutorialAnchor }}>
              <SubdomainContext.Provider value={{ slug }}>
                <Routes />
              </SubdomainContext.Provider>
            </TutorialContext.Provider>
            <ServiceWorkerReloader />
            <ContactUsButton />
          </Provider>
        </GoogleOAuthProvider>
      </ErrorBoundary>
    </RollbarProvider>
  );
};

export default withTranslation()(App);
