import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import { MenuUrl } from '../../enums/menuUrl';
import { JourneyInterface } from '../../../features/journey/Journey';
import { useCustomStyle, useJourneysHome } from '../../functions/hooks';
import { Container, LogoData, Name, TopData } from './styles';
import Button from '../buttons/Button';
import { colors } from '../../functions/colors';
import JourneyDependencies from './JourneyDependencies';
import { t } from '../../../i18n';
import { RootStateGlobal } from '../../../store/reducer';

interface PJourneyCard {
  journey: JourneyInterface;
}

const JourneyCard = ({ journey }: PJourneyCard) => {
  const history = useHistory();
  const { textColor, primaryColor } = useCustomStyle();
  const journeyAsHome = useJourneysHome();
  const { userData } = useSelector((state: RootStateGlobal) => state.userReducer);
  const { id, blocked } = journey;
  const [showDependencies, setShowDependencies] = useState(false);

  const handleOpenJourney = () => {
    if (blocked) {
      setShowDependencies(true);
    } else {
      history.push(`${MenuUrl.journeysList}/${id}/play`, {
        exitToPath: journeyAsHome ? MenuUrl.home : MenuUrl.journeysList,
      });
    }
  };

  let buttonText = t('play');

  // TODO: While we don't have a specific solution for companies/custom settings, we'll handle it ugly like so:
  const aquilaCompanyId = 'cdb33eac-2d6f-41b4-9d91-5ee5f664fadb'; // Aquila
  const isAquila = userData && userData.idCompany === aquilaCompanyId;
  if (isAquila) buttonText = 'INICIAR';

  return (
    <>
      {showDependencies && (
        <JourneyDependencies journey={journey} setShowDependencies={setShowDependencies} />
      )}
      <Container>
        <LogoData source={journey.image} />
        <TopData>
          <Name style={textColor}>{journey.name}</Name>
        </TopData>
        {/* TODO: Progress bar */}
        {/* <div
          style={{
            width: '95%',
            height: 15,
            backgroundColor: 'green',
            color: 'red',
            alignSelf: 'center',
            margin: 6,
            borderRadius: 4,
          }}
        /> */}
        <Button
          style={{
            fontSize: '15px',
            fontWeight: '600',
            height: 42,
            backgroundColor: blocked ? colors.orange : primaryColor.color || colors.purpleRegular,
            margin: '8px',
            maxWidth: '90%',
          }}
          onClick={() => handleOpenJourney()}
        >
          {blocked ? t('journeyBlock') : buttonText}
        </Button>
        {/* TODO: Info section */}
        {/* <ContainerInfo style={{ marginTop: '16px' }}>
          <PrizeContainer>
            <p style={{ color: 'white', fontSize: '10px', fontWeight: '700' }}>Prêmio: </p>
            <img style={{ height: 24 }} src="/images/magicashCash.png" alt="" />
            <p style={{ fontSize: '10px', fontWeight: '700' }}>100</p>
          </PrizeContainer>
          <TimerText>22 d : 12 h : 15 m : 30 s</TimerText>
        </ContainerInfo> */}
      </Container>
    </>
  );
};

export default JourneyCard;
