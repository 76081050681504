import React, { CSSProperties, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';

import Carousel, { Dots } from '@brainhubeu/react-carousel';
import ReactPlayer from 'react-player';
import { useDispatch, useSelector } from 'react-redux';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { colors } from '../../shared/functions/colors';
import {
  Container,
  Title,
  Content,
  DotContainer,
  TopContainer,
  Slide,
  ContainerImage,
  BoxButton,
  DownloadLink,
  StepButtonText,
} from './styles';
import CleanArrowLeft from '../../shared/icons/arrows/cleanArrowLeft';
import '@brainhubeu/react-carousel/lib/style.css';
import ButtonIcon from '../../shared/components/buttons/buttonIcon/ButtonIcon';
import Button from '../../shared/components/buttons/Button';
import PdfViewer from '../../shared/components/pdf/PdfViewer';
import { setFullScreen } from '../../store/reducers/geral/actions';
import { RootStateGlobal } from '../../store/reducer';
import RichTextEditor from '../richEditor/RichTextEditor';
import { useCustomStyle } from '../../shared/functions/hooks';
import CleanArrowRight from '../../shared/icons/arrows/cleanArrowRight';
import { PlayableChallenge as ChallengeI } from '../../shared/constants/types';
import { Tournament as TournamentI } from '../../shared/modals/tournament/Tournament';
import { ModalData } from '../../shared/modals/geral/geral';
import { actions as geralActions } from '../../store/reducers/geral';
import Challenge from '../challenge/Challenge';
import { ChallengeParamsInterface } from '../../shared/services/challenges';
import { handleChallengeSubmission } from '../../shared/functions/gameStarters/challenges';
import {
  isDownloadable,
  isImage,
  isPdf,
  isSelfHostedVideo,
  isVideo,
  isWebsite,
  isScorm,
} from '../../shared/functions/mediaType';
import { Presentation as PresentationI } from '../../shared/modals/presentation/Presentation';
import { t } from '../../i18n';
import { handleStartContest } from '../../shared/functions/gameStarters/contests';

interface PresentationLocationState {
  gameable: ChallengeI | TournamentI;
  exitToPath: string;
  title: string;
  customBackground: string;
  isInLibraryContext?: boolean;
}

const Presentation = () => {
  const dispatch = useDispatch();
  const location = useLocation<never>();
  const history = useHistory();
  const [dotIndex, setDotIndex] = useState(0);
  const { userData } = useSelector((state: RootStateGlobal) => state.userReducer);
  const {
    gameable,
    exitToPath,
    title,
    customBackground,
    isInLibraryContext,
  } = location.state as PresentationLocationState;
  const setModalData = (x: ModalData) => dispatch(geralActions.setModal(x));
  const closeModal = () => dispatch(geralActions.closeModal());
  const { presentations } = gameable;
  const { primaryColor, secondaryColor } = useCustomStyle();
  const isChallenge = 'mechanics' in gameable;
  const isContest = 'idTourney' in gameable;
  const isPlayable = isChallenge || isContest;
  const [playingUrl, setPlayingUrl] = useState('');

  const onIndexChange = (value: number) => {
    setDotIndex(value);
  };

  const onSkip = () => {
    if (isInLibraryContext || dotIndex === presentations.length - 1) {
      history.go(-1);
    } else {
      setDotIndex(presentations.length - 1);
    }
  };

  const handleOpenIframe = (mediaUrl: string) => {
    dispatch(setFullScreen({ mediaUrl, mediaType: 'website' }));
  };

  const handleOpenFullScreen = (mediaUrl: string) => {
    dispatch(setFullScreen({ mediaUrl, mediaType: 'image' }));
  };

  const handleOpenGame = (gameable: ChallengeI | TournamentI) => {
    if (isChallenge) {
      setModalData({
        show: true,
        height: '60%',
        children: (
          <Challenge
            challenge={gameable as ChallengeI}
            customBackground={customBackground}
            title={title}
            handleSubmission={(params: ChallengeParamsInterface, challengeId: string) =>
              handleChallengeSubmission(
                params,
                challengeId,
                () => {
                  history.push(exitToPath);
                },
                dispatch,
              )
            }
          />
        ),
        title: t('more'),
      });
    } else if (isContest) {
      handleStartContest(gameable as TournamentI, exitToPath, history, closeModal, setModalData);
    }
  };

  const handleArrowLeft = () => {
    if (dotIndex > 0) {
      setDotIndex(dotIndex - 1);
    }
  };

  const handleArrowRight = () => {
    if (dotIndex < presentations.length - 1) {
      setDotIndex(dotIndex + 1);
    }
  };

  const renderMedia = (slide: PresentationI) => {
    const { mediaWidth, mediaHeight, mediaUrl, mediaType, allowDownload, scorm } = slide;

    if (!mediaUrl) {
      return null;
    }

    if (isPdf(mediaType)) {
      return (
        <PdfViewer
          width={window.innerWidth < 400 ? window.innerWidth - 60 : 340}
          urlImage={mediaUrl}
          showButtonFullScreen
          canDownload={allowDownload}
        />
      );
    }
    if (isImage(mediaType)) {
      return (
        <ContainerImage onClick={() => handleOpenFullScreen(mediaUrl)}>
          <img src={mediaUrl} alt="slide_image" />
        </ContainerImage>
      );
    }
    if (isVideo(mediaType)) {
      return (
        ReactPlayer.canPlay(mediaUrl) && (
          <ReactPlayer
            onPlay={() => setPlayingUrl(slide.mediaUrl)}
            onPause={() => setPlayingUrl('')}
            playing={playingUrl === slide.mediaUrl}
            url={mediaUrl}
            width="100%"
            height="240px"
            config={{
              youtube: {
                playerVars: {
                  controls: 1,
                  fs: 1,
                  playsinline: 0,
                },
              },
              vimeo: {
                playerOptions: {
                  controls: true,
                },
              },
            }}
          />
        )
      );
    }
    if (isSelfHostedVideo(mediaType)) {
      const style: CSSProperties = { position: 'relative', height: 0 };
      style.paddingBottom =
        mediaWidth && mediaHeight
          ? `${(mediaHeight / mediaWidth) * 100}%`
          : '56.25%'; /* 56.25% => 16:9 */

      return (
        <div style={style}>
          <iframe
            title="Vídeo"
            id={mediaUrl}
            src={mediaUrl}
            style={{
              border: 'none',
              borderRadius: 10,
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
            }}
            allow="accelerometer;gyroscope;autoplay;encrypted-media;picture-in-picture"
            allowFullScreen
          />
        </div>
      );
    }
    if (isWebsite(mediaType)) {
      return (
        <Button
          typeButton="primary-pink"
          customBackground={primaryColor.color}
          onClick={() => handleOpenIframe(mediaUrl)}
        >
          {t('open')}
        </Button>
      );
    }
    if (isDownloadable(mediaType)) {
      return (
        <div style={{ marginTop: 16, textAlign: 'left' }}>
          <DownloadLink href={mediaUrl} download>
            {t('downloadFile')}
          </DownloadLink>
        </div>
      );
    }
    if (isScorm(mediaType)) {
      if (scorm?.length) {
        return scorm.map(content => (
          <Button
            key={content.mediaUrl}
            style={{
              background: 'none',
              border: `2px solid ${primaryColor.color || colors.purpleRegular}`,
              color: primaryColor.color || colors.purpleRegular,
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              marginTop: 8,
            }}
            onClick={() => window.open(content.mediaUrl, '_blank', 'noreferrer')}
          >
            <span style={{ marginRight: 4 }}>{content.title || t('openLesson')}</span>
            <OpenInNewIcon />
          </Button>
        ));
      }
    }

    return null;
  };

  const buildSkipButtonText = () => {
    if (isInLibraryContext) return t('exit');

    return dotIndex < presentations.length - 1 ? t('skip') : t('back').toUpperCase();
  };

  const renderPlay = () => {
    // TODO: While we don't have a specific solution for companies/custom settings, we'll handle it ugly like so:
    const aquilaCompanyId = 'cdb33eac-2d6f-41b4-9d91-5ee5f664fadb'; // Aquila
    let buttonText = t('play');

    if (userData) {
      if (['Buser', 'IFL BH'].includes(userData.company.name)) {
        buttonText = t('answerQuestions');
      }

      const isAquila = userData.idCompany === aquilaCompanyId;
      if (isAquila) {
        buttonText = 'INICIAR';
      }
    }

    return (
      isPlayable && (
        <Button
          onClick={() => handleOpenGame(gameable)}
          className="presentation-play-stretch"
          customBackground={primaryColor.color}
          // style={{ margin: presentations.length <= 1 ? '0 0 20px 0' : '10px 0' }}
        >
          {buttonText}
        </Button>
      )
    );
  };

  useEffect(() => {
    setPlayingUrl('');
  }, [dotIndex]);

  const isLastSlide = (size: number, index: number) => size - 1 === index;

  return (
    <Container>
      <TopContainer>
        <ButtonIcon
          onClick={() => {
            history.go(-1);
          }}
        >
          <CleanArrowLeft color={primaryColor.color || colors.purpleRegular} />
        </ButtonIcon>
        <Title style={{ fontSize: 14, lineHeight: '21px', ...primaryColor }}>
          <strong>{t('presentation')}</strong>
        </Title>
      </TopContainer>
      <Content>
        {/* @ts-expect-error Carousel is buggy */}
        <Carousel value={dotIndex} onChange={onIndexChange}>
          {presentations.map((slide, index) => {
            return (
              <Slide key={`slide_presentation_${index}`}>
                {slide.description && <RichTextEditor value={slide.description} />}
                {renderMedia(slide)}
              </Slide>
            );
          })}
        </Carousel>
      </Content>
      <BoxButton>
        {presentations.length > 1 ? (
          <DotContainer customBackground={primaryColor.color}>
            <Dots value={dotIndex} onChange={onIndexChange} number={presentations.length} />
            <div
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignContent: 'center',
                alignItems: 'center',
              }}
            >
              <Button
                style={{
                  background: primaryColor.color || colors.purpleRegular,
                  width: isLastSlide(presentations.length, dotIndex) ? '100%' : '50%',
                  marginRight: '20px',
                }}
                onClick={handleArrowLeft}
              >
                <CleanArrowLeft color={colors.white} width={40} height={40} />
                <StepButtonText>{t('previous')}</StepButtonText>
              </Button>
              {isLastSlide(presentations.length, dotIndex) ? (
                renderPlay()
              ) : (
                <Button
                  style={{
                    background: primaryColor.color || colors.purpleRegular,
                    width: '50%',
                  }}
                  onClick={handleArrowRight}
                >
                  <StepButtonText>{t('next')}</StepButtonText>
                  <CleanArrowRight color={colors.white} width={40} height={40} />
                </Button>
              )}
            </div>
          </DotContainer>
        ) : (
          <div style={{ marginBottom: 10 }}>{renderPlay()}</div>
        )}
        <Button
          style={{
            background: 'none',
            border: `2px solid ${primaryColor.color || colors.purpleRegular}`,
            color: primaryColor.color || colors.purpleRegular,
          }}
          onClick={onSkip}
        >
          {buildSkipButtonText()}
        </Button>
      </BoxButton>
    </Container>
  );
};

export default Presentation;
