import React, { ChangeEvent, FormEvent, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import Button from '../../shared/components/buttons/Button';
import Input from '../../shared/components/input/Input';
import { ModalData, SnackbarData } from '../../shared/modals/geral/geral';
import { operations as operationsUser } from '../../store/reducers/user';
import { actions as geralAction } from '../../store/reducers/geral';
import { Box } from '../../shared/components/styles/styledBox';
import { BodyUserLoginForm, BodyUserLogin } from '../../shared/modals/user/bodies';
import { BoxForgetPassword, Container, ImageFlagContainer, ImgLogo } from './styles';
import { colors } from '../../shared/functions/colors';
import ButtonText from '../../shared/components/buttons/buttonText/ButtonText';
import ForgetPassword from './forgetPassword/ForgetPassword';
import { actionAnalitycs } from '../../shared/functions/analytics';
import { ACTIONS_LOGIN, CATEGORIES } from '../../shared/constants/constantsAnalytics';
import { getImageCompany } from '../../shared/functions/imageCompany';
import { isLogged } from '../../shared/functions/connection/auth';
import { RootStateGlobal } from '../../store/reducer';
import { MenuUrl } from '../../shared/enums/menuUrl';
import { CompanyPreferenceStylesOperations } from '../../store/reducers/companyPreferenceStyles';
import { useCustomStyle } from '../../shared/functions/hooks';
import GoogleSSO from '../sso/GoogleSSO';
import { SSODataInterface } from '../self_sign_up/SelfSignUp';
import { fonts } from '../../shared/functions/fonts';
import { SubdomainContext } from '../../App';
import TutoText from '../../shared/components/other/tutoText/TutoText';
import { t } from '../../i18n';
import { LanguageSelector } from '../../shared/components/languageSelector/LanguageSelector';

const Login = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const setSnackBar = (x: SnackbarData) => dispatch(geralAction.setSnackbarData(x));
  const setModal = (x: ModalData) => dispatch(geralAction.setModal(x));
  const { slug } = useContext(SubdomainContext);
  const reqLogin = async (x: BodyUserLogin) => dispatch(operationsUser.reqLogin(x, slug));
  const getUserData = async () => dispatch(operationsUser.getUserData({ subdomain: slug }));
  const getCompanyPreferenceStyles = async () =>
    dispatch(CompanyPreferenceStylesOperations.getCompanyStyle({ subdomain: slug }));
  const { userData } = useSelector((state: RootStateGlobal) => state.userReducer);
  const [loading, setLoading] = useState<boolean>(false);
  const [login, setLogin] = useState<BodyUserLoginForm>({
    email: '',
    password: '',
  });

  // TODO: Ugly hotfix solution since we dont have today a better approach o server. Continental Pneus Id: 6d17892f-430c-4b94-9d74-2624740be7e8
  // Some Partners don't have slug, so we need to check the hostname. See backend for more details.
  const isContinentalPneus =
    window.location.hostname.includes('contiacademy.com.br') || slug === 'continental-pneus';

  const {
    primaryColor,
    secondaryColor,
    textColor,
    backgroundPrimary,
    backgroundSecondary,
  } = useCustomStyle();

  useEffect(() => {
    actionAnalitycs(CATEGORIES.LOGIN, ACTIONS_LOGIN.OPEN, window.location.pathname);
    getCompanyPreferenceStyles();
  }, []);

  useEffect(() => {
    if (!userData) return;

    if (userData.frozenAt) history.push(MenuUrl.updateInfo);
    else history.push(MenuUrl.home);
  }, [userData]);

  useEffect(() => {
    const verifyLogged = async () => {
      if (isLogged()) {
        setLoading(true);
        try {
          await getUserData();
          setLoading(false);
        } catch (e) {
          setLoading(false);
        }
      }
    };
    verifyLogged();
  }, []);

  const handleLogin = async (event?: FormEvent<HTMLFormElement>) => {
    event?.preventDefault();

    try {
      setLoading(true);
      await reqLogin(login);
    } catch (e) {
      if (e instanceof Error) {
        setSnackBar({
          message: e.message,
          show: true,
          // TODO TRATAR MENSAGENS DO BACKEND
          type: e.message.startsWith('Para concluir o login') ? 'success' : 'error',
        });
      }
    }
    setLoading(false);
  };

  const handleChangeEmail = (event: ChangeEvent<HTMLInputElement>) => {
    setLogin({
      ...login,
      email: event.target.value,
    });
  };

  const handleChangePassword = (event: ChangeEvent<HTMLInputElement>) => {
    setLogin({
      ...login,
      password: event.target.value,
    });
  };

  const handleOpenForgetPassword = () => {
    setModal({
      show: true,
      title: '',
      children: <ForgetPassword />,
    });
  };

  // If the Google's OAuth token has changed, it means the user has just
  // decided to use the SSO method/button to signup. Do notice that the
  // `googleTokenId` attribute is set by the `ssoCallback` method below - which
  // is called from `GoogleSSO` component.
  useEffect(() => {
    if (login.googleTokenId?.length) handleLogin();
  }, [login.googleTokenId]);

  const ssoCallback = (ssoData: SSODataInterface) => {
    setLogin(l => ({ ...l, googleTokenId: ssoData.googleTokenId }));
  };

  const render = () => {
    return (
      <Container style={backgroundPrimary}>
        <Box style={{ maxWidth: '420px' }}>
          <form onSubmit={handleLogin}>
            <ImgLogo src={getImageCompany()} />
            <Input
              title="Login:"
              placeholder={t('placeholderType')}
              onChange={handleChangeEmail}
              type="text"
              style={{ marginBottom: 16 }}
              customBackground={backgroundSecondary.backgroundColor}
              customTextColor={textColor.color}
              autoFocus
            />
            <Input
              placeholder={t('placeholderType')}
              title={`${t('password')}:`}
              onChange={handleChangePassword}
              customBackground={backgroundSecondary.backgroundColor}
              customTextColor={textColor.color}
              type="password"
            />
            <BoxForgetPassword>
              <ButtonText
                onClick={handleOpenForgetPassword}
                color={secondaryColor.color || colors.orange}
                type="button"
              >
                {t('forgotPassword')}
              </ButtonText>
            </BoxForgetPassword>
            <Button loading={loading} customBackground={primaryColor.color}>
              Login
            </Button>
            {!isContinentalPneus && (
              <>
                <div style={{ opacity: '50%' }}>
                  <hr style={{ marginBottom: -45, marginTop: 42 }} />
                  <div
                    style={{
                      fontFamily: fonts.semiBold,
                      color: colors.white,
                      width: 45,
                      background: colors.background,
                      margin: '32px auto 24px auto',
                    }}
                  >
                    {t('or')}
                  </div>
                </div>
                <GoogleSSO text="signin_with" callback={ssoCallback} loading={loading} />
              </>
            )}
          </form>
          <TutoText />
        </Box>
        <ImageFlagContainer style={{ position: 'absolute' }}>
          <LanguageSelector />
        </ImageFlagContainer>
      </Container>
    );
  };

  return render();
};

export default Login;
